import React, { useMemo } from 'react';
import { Database, Filter, Group } from 'lucide-react';

const MetadataSection = ({ icon: Icon, title, items, badgeColorClass }) => {
  if (!items || items.length === 0) return null;
  
  // Filter out any empty strings, null, or undefined values
  const validItems = items.filter(item => item && item.trim().length > 0);
  if (validItems.length === 0) return null;
  
  return (
    <div className="flex items-start space-x-2 flex-shrink-0">
      <div className="mt-1">
        <Icon size={16} className="text-gray-600" />
      </div>
      <div>
        <div className="font-medium text-gray-700 mb-1">{title}</div>
        <div className="flex flex-wrap gap-2">
          {validItems.map((item, index) => (
            <span
              key={index}
              className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs ${badgeColorClass}`}
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const QueryMetadata = ({ sqlQuery }) => {
  const extractMetadata = useMemo(() => {
    try {
      if (!sqlQuery) return { tables: [], filterColumns: [], groupByColumns: [] };

      const tables = new Set();
      const filterColumns = new Set();
      const groupByColumns = new Set();

      // Function to properly format table names
      const formatTableName = (name) => {
        if (!name) return '';
        const parts = name.split('.');
        if (parts.length < 3) return '';
        
        // Get the last part (actual table name)
        let tableName = parts[parts.length - 1].replace(/`/g, '');
        
        // Convert to Title Case
        return tableName
          .split(/[-_]/)
          .map(word => word
            .split('_')
            .map(subWord => subWord.charAt(0).toUpperCase() + subWord.slice(1).toLowerCase())
            .join(' ')
          )
          .join(' ');
      };

      // Function to extract column name from a complex expression
      const extractColumnFromExpression = (expr) => {
        if (!expr) return '';

        // Remove any quoted strings to avoid interference
        expr = expr.replace(/'[^']*'/g, '');

        // Handle various SQL functions
        const functionPatterns = [
          // EXTRACT(... FROM column)
          { pattern: /EXTRACT\s*\([^)]*FROM\s+([^\s),]+)/i, group: 1 },
          // LOWER(column), UPPER(column), etc.
          { pattern: /(?:LOWER|UPPER|TRIM|CAST)\s*\(\s*([^\s(),]+)/i, group: 1 },
          // DATE(column), DATETIME(column)
          { pattern: /(?:DATE|DATETIME)\s*\(\s*([^\s(),]+)/i, group: 1 },
          // NULLIF(column, value)
          { pattern: /NULLIF\s*\(\s*([^\s,)]+)/i, group: 1 },
          // CONCAT function - extract all column references
          { pattern: /CONCAT\s*\(([^)]+)\)/i, group: 1 },
          // MAX, MIN, AVG, SUM functions
          { pattern: /(?:MAX|MIN|AVG|SUM)\s*\(\s*([^\s(),]+)/i, group: 1 },
          // CASE WHEN ... THEN column END
          { pattern: /CASE\s+WHEN\s+.*?\s+THEN\s+([^\s]+)\s+END/i, group: 1 }
        ];

        for (const { pattern, group } of functionPatterns) {
          const match = expr.match(pattern);
          if (match) {
            // For CONCAT, we need to process each argument
            if (pattern.source.includes('CONCAT')) {
              const args = match[group].split(',');
              return args
                .map(arg => extractColumnFromExpression(arg))
                .filter(Boolean)[0]; // Return the first valid column name
            }
            return match[group].replace(/[`\[\]]/g, '').trim();
          }
        }

        // Handle basic column references
        const basicColumn = expr
          .replace(/[`\[\]]/g, '')  // Remove backticks and brackets
          .split(/[\s.,()]+/)       // Split on spaces, dots, commas, parentheses
          .filter(Boolean).pop();   // Take the last non-empty part

        return basicColumn || '';
      };

      // Function to format column names
      const formatColumnName = (name) => {
        if (!name) return '';
        
        // Remove table/alias qualifiers and backticks
        let columnName = name.replace(/`/g, '').trim();

        // Split on dots and take the last part to remove table aliases
        columnName = columnName.split('.').pop();

        // Skip if it's a numeric literal or empty
        if (!columnName || columnName.match(/^[0-9.']+$/)) return '';
        
        // Convert snake_case or camelCase to Title Case
        // First, handle snake_case
        columnName = columnName.replace(/_/g, ' ');
        // Then, handle camelCase
        columnName = columnName.replace(/([a-z])([A-Z])/g, '$1 $2');

        // Convert to Title Case
        return columnName
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
          .trim();
      };

      // Function to split conditions at the top level (ignoring parentheses)
      const splitConditions = (str) => {
        const conditions = [];
        let currentCondition = '';
        let openParentheses = 0;
        let i = 0;
        const len = str.length;
        while (i < len) {
          const char = str[i];
          if (char === '(') {
            openParentheses++;
            currentCondition += char;
            i++;
          } else if (char === ')') {
            openParentheses--;
            currentCondition += char;
            i++;
          } else if ((char === 'A' || char === 'a') && openParentheses === 0) {
            // Possible 'AND'
            const nextThree = str.slice(i, i + 3).toUpperCase();
            if (nextThree === 'AND' && (i === 0 || /\s/.test(str[i - 1])) && (i + 3 === len || /\s/.test(str[i + 3]))) {
              // Found 'AND' not within parentheses
              conditions.push(currentCondition.trim());
              currentCondition = '';
              i += 3; // Skip over 'AND'
            } else {
              currentCondition += char;
              i++;
            }
          } else {
            currentCondition += char;
            i++;
          }
        }
        if (currentCondition.trim()) {
          conditions.push(currentCondition.trim());
        }
        return conditions;
      };

      // Extract tables
      const tablePattern = /(?:FROM|JOIN)\s+`?([^`\s]+(?:\.[^`\s]+){2})`?(?:\s+[a-zA-Z0-9_]+)?/gi;
      let match;
      while ((match = tablePattern.exec(sqlQuery)) !== null) {
        const tableName = formatTableName(match[1]);
        if (tableName) {
          tables.add(tableName);
        }
      }

      // Extract filter columns from WHERE and JOIN conditions
      const conditions = [];
      
      // Extract JOIN conditions
      const joinPattern = /JOIN\s+.*?\s+ON\s+(.*?)(?=\s+(?:WHERE|GROUP|ORDER|LIMIT|$))/gis;
      while ((match = joinPattern.exec(sqlQuery)) !== null) {
        if (match[1]) {
          conditions.push(...splitConditions(match[1]));
        }
      }

      // Corrected WHERE pattern
      const wherePattern = /WHERE\s+(.*?)(?:\s+GROUP\s+BY|\s+ORDER\s+BY|\s+LIMIT|\s*$)/is;
      const whereMatch = sqlQuery.match(wherePattern);
      if (whereMatch) {
        conditions.push(...splitConditions(whereMatch[1]));
      }

      // Process all conditions
      conditions.forEach(condition => {
        // Clean up the condition
        condition = condition.trim();
        
        // Skip conditions that are subqueries or NOT EXISTS clauses
        if (/^\(?\s*(NOT\s+)?EXISTS\s*\(/i.test(condition)) {
          return;
        }
        
        // Extract column names from the condition
        const columnMatches = [];
        
        // Handle function-wrapped columns
        const functionPattern = /(?:LOWER|UPPER|EXTRACT|DATE|DATETIME|NULLIF|CONCAT|CASE|MAX|MIN|AVG|SUM)\s*\([^)]+\)/gi;
        let functionMatch;
        while ((functionMatch = functionPattern.exec(condition)) !== null) {
          const column = extractColumnFromExpression(functionMatch[0]);
          if (column) columnMatches.push(column);
        }
        
        // Handle basic comparisons
        const basicPattern = /([^\s=<>!]+)\s*[=<>!]/;
        const basicMatch = condition.match(basicPattern);
        if (basicMatch) {
          const column = extractColumnFromExpression(basicMatch[1]);
          if (column) columnMatches.push(column);
        }
        
        // Handle LIKE and IN conditions
        const otherPatterns = [
          /([^\s]+)\s+LIKE/i,
          /([^\s]+)\s+IN/i,
          /([^\s]+)\s+BETWEEN/i
        ];
        
        otherPatterns.forEach(pattern => {
          const match = condition.match(pattern);
          if (match) {
            const column = extractColumnFromExpression(match[1]);
            if (column) columnMatches.push(column);
          }
        });

        // Format and add unique columns to filterColumns
        columnMatches.forEach(column => {
          const formatted = formatColumnName(column);
          if (formatted) {
            filterColumns.add(formatted);
          }
        });
      });

      // Extract GROUP BY columns
      const groupByPattern = /GROUP\s+BY\s+(.*?)(?:\s+ORDER\s+BY|\s+LIMIT|\s*$)/is;
      const groupByMatch = sqlQuery.match(groupByPattern);
      if (groupByMatch) {
        const columns = groupByMatch[1].split(',');
        columns.forEach(col => {
          const column = extractColumnFromExpression(col);
          const formatted = formatColumnName(column);
          if (formatted) {
            groupByColumns.add(formatted);
          }
        });
      }

      return {
        tables: Array.from(tables).filter(Boolean),
        filterColumns: Array.from(filterColumns).filter(Boolean),
        groupByColumns: Array.from(groupByColumns).filter(Boolean)
      };
    } catch (error) {
      console.error('Error in extractMetadata:', error);
      return {
        tables: [],
        filterColumns: [],
        groupByColumns: []
      };
    }
  }, [sqlQuery]);

  if (!sqlQuery) return null;

  const sections = [
    {
      icon: Database,
      title: "Tables Used",
      items: extractMetadata.tables,
      badgeColorClass: "bg-blue-50 text-blue-700"
    },
    {
      icon: Filter,
      title: "Filter Columns",
      items: extractMetadata.filterColumns,
      badgeColorClass: "bg-green-50 text-green-700"
    },
    {
      icon: Group,
      title: "Grouped By",
      items: extractMetadata.groupByColumns,
      badgeColorClass: "bg-purple-50 text-purple-700"
    }
  ].filter(section => section.items && section.items.length > 0);

  return (
    <div className="text-sm">
      {/* Mobile Layout (Vertical) */}
      <div className="md:hidden space-y-4">
        {sections.map((section, index) => (
          <MetadataSection key={index} {...section} />
        ))}
      </div>

      {/* Desktop Layout (Horizontal) */}
      <div className="hidden md:flex md:flex-row md:space-x-8 md:divide-x divide-gray-200">
        {sections.map((section, index) => (
          <div key={index} className={index === 0 ? '' : 'pl-8'}>
            <MetadataSection {...section} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QueryMetadata;
