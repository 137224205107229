import html2canvas from 'html2canvas';

export const formatLargeNumber = (num) => {
  if (num === null || num === undefined || isNaN(num)) return '0';
  
  const roundedNum = parseFloat(num.toFixed(2));
  if (roundedNum >= 1000000000) {
    return (roundedNum / 1000000000).toFixed(2).replace(/\.?0+$/, '') + 'B';
  }
  if (roundedNum >= 1000000) {
    return (roundedNum / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'M';
  }
  if (roundedNum >= 1000) {
    return (roundedNum / 1000).toFixed(2).replace(/\.?0+$/, '') + 'K';
  }
  return roundedNum.toString();
};

export const formatLabel = (label) => {
  if (!label) return '';
  
  try {
    return label
      .toString()
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  } catch (error) {
    console.error('Error formatting label:', error);
    return String(label);
  }
};

export const createImageWrapper = (chartRef, cardTitle, statsData, selectedYAxis) => {
  if (!chartRef?.current) {
    console.error('Chart reference not found');
    throw new Error('Chart reference not found');
  }

  // Create wrapper div
  const wrapper = document.createElement('div');
  wrapper.style.backgroundColor = '#ffffff';
  wrapper.style.padding = '20px';
  wrapper.style.width = chartRef.current.offsetWidth + 'px';
  wrapper.style.position = 'relative';
  wrapper.style.fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif';

  // Add title if available
  if (cardTitle) {
    const titleDiv = document.createElement('div');
    titleDiv.style.fontSize = '18px';
    titleDiv.style.fontWeight = 'bold';
    titleDiv.style.marginBottom = '12px';
    titleDiv.style.color = '#374151';
    titleDiv.textContent = cardTitle;
    wrapper.appendChild(titleDiv);
  }

  // Add stats if available
  if (statsData && typeof statsData === 'object') {
    const statsDiv = document.createElement('div');
    statsDiv.style.backgroundColor = '#f3f4f6';
    statsDiv.style.padding = '8px 16px';
    statsDiv.style.borderRadius = '6px';
    statsDiv.style.marginBottom = '16px';
    statsDiv.style.fontSize = '14px';
    
    if (selectedYAxis) {
      const yAxisSpan = document.createElement('div');
      yAxisSpan.style.color = '#6b7280';
      yAxisSpan.style.fontSize = '12px';
      yAxisSpan.style.marginBottom = '4px';
      yAxisSpan.textContent = formatLabel(selectedYAxis);
      statsDiv.appendChild(yAxisSpan);
    }

    const statsContent = document.createElement('div');
    statsContent.style.color = '#4b5563';
    
    let statsHtml = '';
    if ('sum' in statsData) {
      statsHtml += `<span style="margin-right: 16px">
        <strong>Sum:</strong> ${formatLargeNumber(parseFloat(statsData.sum))}
      </span>`;
    }
    if ('avg' in statsData) {
      statsHtml += `<span>
        <strong>Avg:</strong> ${formatLargeNumber(parseFloat(statsData.avg))}
      </span>`;
    }
    
    statsContent.innerHTML = statsHtml;
    statsDiv.appendChild(statsContent);
    wrapper.appendChild(statsDiv);
  }

  // Clone the chart
  try {
    const chartClone = chartRef.current.cloneNode(true);
    wrapper.appendChild(chartClone);
  } catch (error) {
    console.error('Error cloning chart:', error);
    throw new Error('Failed to clone chart element');
  }

  // Add watermark
  const watermark = document.createElement('div');
  watermark.style.cssText = `
    position: absolute;
    bottom: 17px;
    right: 12px;
    padding: 6px 10px;
    border-radius: 4px;
    z-index: 1000;
    text-align: right;
    font-style: italic;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, system-ui, sans-serif;
    font-size: 12px;
    line-height: 1;
    background-color: rgba(255, 255, 255, 0.9);
  `;

  const text = document.createElement('span');
  text.style.cssText = 'color: #FFADFF;';
  text.textContent = 'generated with ';

  const brandName = document.createElement('span');
  brandName.style.cssText = 'color: #170DF2; font-weight: bold;';
  brandName.textContent = 'tango AI';

  watermark.appendChild(text);
  watermark.appendChild(brandName);
  wrapper.appendChild(watermark);

  return wrapper;
};

export const captureImage = async (wrapper) => {
  if (!wrapper) {
    throw new Error('Wrapper element is required');
  }

  try {
    // Append wrapper to body temporarily
    document.body.appendChild(wrapper);
    
    // Wait for any animations or transitions to complete
    await new Promise(resolve => setTimeout(resolve, 100));

    // Log dimensions for debugging
    console.log('Capturing image with dimensions:', {
      wrapper: {
        width: wrapper.offsetWidth,
        height: wrapper.offsetHeight
      }
    });

    // Capture the image
    const canvas = await html2canvas(wrapper, {
      scale: 2,
      backgroundColor: '#ffffff',
      logging: true,
      useCORS: true,
      onclone: (clonedDoc) => {
        // Ensure SVG elements maintain their dimensions
        const svgElements = clonedDoc.getElementsByTagName('svg');
        Array.from(svgElements).forEach(svg => {
          svg.style.width = '100%';
          svg.style.height = '100%';
        });
      }
    });

    // Clean up
    document.body.removeChild(wrapper);

    console.log('Image captured successfully:', {
      canvas: {
        width: canvas.width,
        height: canvas.height
      }
    });

    return canvas;
  } catch (error) {
    // Clean up on error
    if (document.body.contains(wrapper)) {
      document.body.removeChild(wrapper);
    }
    console.error('Error capturing image:', error);
    throw error;
  }
};